<template>
    <div>
        <el-table :data="pactlist" height="400px" :header-cell-style="{background:'#f0f0f0',color:'#666'}" v-loading='pactLoading'>
            <el-table-column align="center" type="index" prop="UserID" label="序号" width="50">
            </el-table-column>
            <el-table-column align="center" prop="FTName" label="模板名" width="210"></el-table-column>
            <el-table-column align="center" prop="FTURL" label="" width="110">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" :disabled="scope.row.FTURL == ''?true:false" @click="previewUrl(scope.row.FTURL)">案例预览</el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="FileName" label="文件名" width="210"></el-table-column>
            <el-table-column align="center" prop="UpLoadTime" label="修改时间" width="160"></el-table-column>
            <!-- 可操作 -->
            <el-table-column align="center"  label="操作" width="220" fixed="right" v-if="isAccessory">
                <template slot-scope="scope">
                    <el-button style="padding:0;">
                        <div class="up">
                            <input
                            style="width: 56px;height: 32px;"
                            class="upBox" 
                            type="file" 
                            id="inp"
                            ref="inputs" 
                            @change="fileUpload($event,scope.row)"
                            accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/msword,application/xml-dtd" />
                            上传
                        </div>
                    </el-button>
                    <el-button type="success" size="small" :disabled="scope.row.FileUrl?false:true" @click="upLoadPact(scope.row)">下载</el-button>
                    <el-button type="danger" size="small" :disabled="scope.row.FileUrl?false:true" @click="delPact(scope.row)">删除</el-button>
                </template>
            </el-table-column>
            <!-- 不可操作 -->
            <el-table-column align="center"  label="操作" width="220" fixed="right" v-else>
                <template slot-scope="scope">
                    <el-button type="success" size="small" @click="upLoadPact(scope.row)">下载</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination background 
            class="pagination" 
            @current-change="pactChange"
            :current-page.sync="pactData.page" 
            :page-size="pactData.pagesize" 
            layout="total, prev, pager, next, jumper"
            :total="pactData.total">
        </el-pagination>
        <el-alert type="warning" :closable="false">
            <span slot="title">
            <span> 温馨提示：</span><br />
            <span> 1.根据大数据平台和资金监管要求，此处将上传各类附件（推荐上传PDF文件）；</span><br />
            <span> 2.请严格遵守文件名规范，参照模板填写！</span><br />
            <span> 3.上传电子版后，纸质版全部一式两份，按要求用印，寄送到运服通平台，统一送交第三方/有权机构；</span><br />
            <span> 4.您可以对同名文件进行上传（再次上传即覆盖）、下载、删除；</span><br />
            <span> 5.请务必核实文件的真实性、有效性、完整性，因用户错/漏操作导致的损失由用户自行承担一切责任！</span><br />
            </span>
        </el-alert>
    </div>
</template>

<script>
export default {
    props:['pactlist','pactData','pactLoading','isAccessory'],
    data() {
        return {

        }
    },
    methods:{
        // 上传
        fileUpload(event,item){
            this.$emit('fileUpload',event,item,this.$refs.inputs)
            event.target.value = ''
        },
        // 下载
        upLoadPact(item){
            window.open(item.FileUrl)
        },
        // 删除
        delPact(item){
            this.$emit('delPact',item)
        },
        // 分页
        pactChange(e){
            this.$emit('pactChange',e)
        },
        // 预览案例
        previewUrl(url){
            window.open(url)
        }
    }
}
</script>

<style lang="scss" scoped>
.up{
    width: 56px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    cursor: pointer;
    position: relative;
    color: #fff;
    background: #409EFF;
    border-radius: 3px;
    .updiv{
      position: absolute;
      cursor: pointer;
    }
    .upBox{
      width: 89px;
      height: 40px;
      position: absolute;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
}
</style> 