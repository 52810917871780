import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//保存货主基本信息
export const saveGoodsOwnerInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/AddenterPriseInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询货主基本信息
export const getGoodsOwnerInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetenterPriseInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 修改/新增服务商/货主信息
export const AddAllEnterPrise = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/AddAllEnterPrise`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 撤回资料审核
export const UpdateStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/UpdateStatus`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}





// 暂存服务商/货主信息
export const StagingAllEnterPrise = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Sys/post/StagingEnterpriseBaseInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取暂存服务商/货主信息
export const getStagingAllEnterPrise = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Sys/get/StagingEnterpriseBaseInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取企业行业分类
export const getTCList = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetTCList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取业务类型
export const getTask = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetTaskTypeList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取场景类型
export const getTaskScene = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetTaskSceneTypeList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取附件列表
export const GetGetAppendixList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/GetGetAppendixList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 新增附件
export const UpLoadAddEAppendix = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/UpLoadAddEAppendixInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除附件
export const removeEAppendix = (params) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/api/ConsInfo/RemoveEAppendixInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 更新附件
export const UpLoadUpdateEAppendixInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/UpLoadUpdateEAppendixInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 缓存保存选中的附件
export const GetAppendixID = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetAppendixID?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取电子签署
export const GetFDDSignAuthUrl = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/FadadaElectronTag/GetFDDSignAuthUrl?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 账户变更结果查询
export const UpdatePublicQuery = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Yumin/Account/UpdatePublicQuery`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}