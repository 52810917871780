<template>
    <div>
        <el-alert type="warning" :closable="false" style="margin-bottom:10px;" v-if="isOpenBeneficiary">
            <p>温馨提示：</p>
            <p>点击 “上传” 按钮，上传身份证正/反面可自动识别及填充</p>
        </el-alert>
        <!-- 可编辑表格 -->
        <vxe-table v-if="isOpenBeneficiary" border resizable show-overflow :data="beneficiaryData" :edit-config="{trigger: 'click', mode: 'cell'}" max-height="740px">
            <vxe-table-column width="80" align="center" field="BeneficiaryName" title="姓名" :edit-render="{name: 'input', autoselect: true}" fixed="left"></vxe-table-column>
            <vxe-table-column width="160" align="center" field="BeneficiaryIDCardNo" title="受益人身份证号" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
            <vxe-table-column width="130" align="center" field="BeneficiaryPhone" title="受益人手机号码" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
            <vxe-table-column width="170" align="center" field="BeneficiaryIDCardStartDate" title="受益人身份证起始日期" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
            <vxe-table-column width="160" align="center" field="BeneficiaryIDCardValidDate" title="受益人身份证有效期" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
            <vxe-table-column width="135" align="center" field="BeneficiaryIDCardUrl" title="身份证正面">
                <template #default="{ row }">
                    <img style="height:40px;cursor: pointer;margin:2px 0 0 0;" :src="row.BeneficiaryIDCardUrl" @click="previewImg(row.BeneficiaryIDCardUrl)">
                </template>
            </vxe-table-column>
            <vxe-table-column width="135" align="center" field="BeneficiaryIDCardBackUrl" title="身份证反面">
                <template #default="{ row }">
                    <img style="height:40px;cursor: pointer;margin:2px 0 0 0;" :src="row.BeneficiaryIDCardBackUrl" @click="previewImg(row.BeneficiaryIDCardBackUrl)">
                </template>
            </vxe-table-column>
            <vxe-table-column width="190" align="center" title="操作" fixed="right">
                <template #default="{ row }">
                    <el-button type="primary" icon="el-icon-upload2" size="small" @click="uploadIDCard(row)">上传</el-button>
                    <el-button type="danger" icon="el-icon-delete" size="small" @click="delBeneficiary(row)">删除</el-button>
                </template>
            </vxe-table-column>
        </vxe-table>
        <!-- 不可编辑表格 -->
        <el-table 
            :data="beneficiaryData" 
            :header-cell-style="{background:'#f0f0f0',color:'#666'}" 
            v-else>
            <el-table-column width="80" fixed align="center" prop="BeneficiaryName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column width="160" align="center" prop="BeneficiaryIDCardNo" label="受益人身份证号" show-overflow-tooltip></el-table-column>
            <el-table-column width="130" align="center" prop="BeneficiaryPhone" label="受益人手机号码" show-overflow-tooltip></el-table-column>
            <el-table-column width="170" align="center" prop="BeneficiaryIDCardStartDate" label="受益人身份证起始日期" show-overflow-tooltip></el-table-column>
            <el-table-column width="160" align="center" prop="BeneficiaryIDCardValidDate" label="受益人身份证有效期" show-overflow-tooltip></el-table-column>
            <el-table-column width="135" align="center" prop="DriverLicenceFace" label="身份证正面" show-overflow-tooltip>
                <template slot-scope="scope">
                    <img style="height:40px;cursor: pointer;" :src="scope.row.BeneficiaryIDCardUrl" @click="previewImg(scope.row.BeneficiaryIDCardUrl)">
                </template>
            </el-table-column>
            <el-table-column width="135" align="center" prop="BeneficiaryIDCardBackUrl" label="身份证反面" show-overflow-tooltip>
                <template slot-scope="scope">
                    <img style="height:40px;cursor: pointer;" :src="scope.row.BeneficiaryIDCardBackUrl" @click="previewImg(scope.row.BeneficiaryIDCardBackUrl)">
                </template>
            </el-table-column>
            <el-table-column fixed="right" align="center" label="操作" width="190">
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-upload2" size="small" disabled>编辑</el-button>
                    <el-button type="danger" icon="el-icon-delete" size="small" disabled>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 上传身份证图片 -->
        <el-dialog class="addDialog" :visible.sync="flag.isUpLoad" width="700px" :modal="false" v-if="isOpenBeneficiary">
            <span slot="title" class="dialog-title">
                上传受益人身份证
            </span>
            <div class="content">
                <div class="upBox">
                    <UploadImg
                        ref="cardOn"
                        :isModel="false"
                        :loading="authForm.beneficiaryID.loading"
                        :uploaded="authForm.beneficiaryID.uploaded"
                        :picUrl="authForm.beneficiaryID.picUrl"
                        :fileType="'Enterprise'"
                        @update:file="updateFile"
                        @change="uploadIDCardJust(authForm.beneficiaryID,'BeneficiaryName','BeneficiaryIDCardNo')"
                        @deleteImg="deleteImg(authForm.beneficiaryID)">
                    </UploadImg>
                    <div class="upText"> 受益人身份证(正面)</div>
                </div>
                <div class="upBox">
                    <UploadImg
                        ref="cardBack"
                        :isModel="false"
                        :loading="authForm.beneficiaryIDBack.loading"
                        :uploaded="authForm.beneficiaryIDBack.uploaded"
                        :picUrl="authForm.beneficiaryIDBack.picUrl"
                        :fileType="'Enterprise'"
                        @update:file="updateFile"
                        @change="uploadIDCardBack(authForm.beneficiaryIDBack,'BeneficiaryIDCardValidDate','BeneficiaryIDCardStartDate')"
                        @deleteImg="deleteImg(authForm.beneficiaryIDBack)">
                    </UploadImg>
                    <div class="upText"> 受益人身份证(反面)</div>
                </div>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="flag.showImg" title="图片预览" :modal="false">
            <img width="100%" :src="currentImgUrl">
        </el-dialog>
    </div>
</template>

<script>
import UploadImg from '@/components/commonCmpt/cropperImage'
export default {
    props:['beneficiaryData',"authForm","isOpenBeneficiary"],
    data(){
        return{
            flag:{
                // 预览图片
                showImg: false,
                // 上传身份证照片
                isUpLoad:false
            },
            // 当前组件正在操作的文件
            currentFile:'',
            // 单个收益人信息
            beneficiaryInfo:'',
            //正在预览的图片
            currentImgUrl: '',
        }
    },
    methods:{
        //设置当前组件正在操作的文件
        updateFile(file) {
            this.currentFile = file
        },
        // 显示上传身份证弹窗
        uploadIDCard(item){
            this.flag.isUpLoad = true
            this.beneficiaryInfo = item
            this.$emit('uplodanMode',item)
        },
        // 上传身份证正面
        uploadIDCardJust(field,name,car){
            this.$emit('uploadIDCardJust',field,name,car,this.currentFile,this.beneficiaryInfo.id)
            this.$refs.cardOn.dialog()
        },
        // 上传身份证反面
        uploadIDCardBack(field,type,date){
            this.$emit('uploadIDCardBack',field,type,date,this.currentFile,this.beneficiaryInfo.id)
            this.$refs.cardBack.dialog()
        },
        // 删除已上传图片
        deleteImg(field){
            this.$emit('deleteImg',field)
        },
        //预览图片
        previewImg(url) {
            if(url){
                this.currentImgUrl = url
                this.flag.showImg = !this.flag.showImg
            }else{
                this.$message.error('请先上传相关证件照片')
            }
        },
        // 删除受益人
        delBeneficiary(item){
            this.$emit('delBeneficiary',item)
        },
    },
    components: {
        UploadImg
    }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
    .upBox{
        width:280px;
        text-align: center;
        .upText{
            font-size: 14px;
            font-weight: bold;
            margin: 10px 0 0 0;
            color: #333333;
        }
    }
}
</style>