import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 查询列表（货主，服务商）列表
export const GetEnterprise = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/PlatForm/GetEnterpriseBaseList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}



// 查询货主/服务商信息
export const GetenterPriseInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/PlatForm/GetenterPriseInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 拒接通过审核
export const enterpriseBase = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/PlatForm/CertificationEnterpriseBase`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取客服列表
export const GetPlatFormCustomer = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/PlatForm/GetPlatFormCustomerServiceList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 指派客服
export const UserByAssign = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/PlatForm/UserByAssignUserID?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 同意合作分成比例
export const ConfirmCommission = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Commission/ConfirmCommission`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台退回重设分成比例
export const BackCommission = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Commission/BackCommission`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取角色列表
export const GetRoles = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/GetRoles`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
